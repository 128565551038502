<template>
	<div>
		<div class="text-center">
			<p>Single sprites that didn't make the cut.</p>
		</div>

		<br>

		<div>
			<img src="@/assets/images/resources/vamp-costumes.png" width="400">
			<div class="md-image-caption">
				Alternate vampire outfits
			</div>
		</div>

		<div class="md-gallery md-gallery--align-bottom">
			<div><img src="@/assets/images/resources/art-assets/skele4_idle.gif" width="200"></div>
			<div><img src="@/assets/images/resources/art-assets/skele4_run.gif" width="200"></div>
		</div>
		<div class="md-image-caption">
			<br>
			The fabled oSkele4.
			<br>The mystery of oSkele4 has entered the mind of many a map maker,
			<br>considering the game has objects for oSkele1, 2, 3 and 5, but no oSkele4.
			<br>Perhaps the mystery was solved when Skele4 was found in the <a href="https://hopoo.tumblr.com/post/152134593474/hey-hopoo-since-you-said-you-could-post-specific" target="_blank">dev's assets pack</a>.
			<br>Or maybe, who Skele4 is depends on you, the one who experiences Deadbolt.
		</div>

		<div class="md-gallery md-gallery--align-bottom">
			<div><img src="@/assets/images/resources/art-assets/reaper_crawl_vent1--crop.gif" width="100"></div>
			<div><img src="@/assets/images/resources/art-assets/reaper_smoke_vent1--crop.gif" width="180"></div>
			<div><img src="@/assets/images/resources/art-assets/reaper_teleport--join-crop.gif" width="170"></div>
		</div>

		<br>

		<div class="md-gallery md-gallery--align-bottom md-gallery--spacious">
			<div><img src="@/assets/images/resources/art-assets/reaper_reflect--crop.gif" width="100"></div>
		</div>
		<div class="md-image-caption">
			This sprite's purpose was restored when DPC version <a href="/dcp#v300">3.0.0</a>
			<br>re-enabled the Scythe's bullet deflect mechanic.
			<br>Curiously, there was a lot of code already in place for this.
		</div>

		<br>

		<div class="md-gallery md-gallery--align-bottom md-gallery--spacious">
			<div><img src="@/assets/images/resources/art-assets/reaper_stab--crop.gif" style="zoom:3"></div>
			<div><img src="@/assets/images/resources/art-assets/reaper_cut_throat--crop.gif" style="zoom:3"></div>
			<div><img src="@/assets/images/resources/art-assets/zombie1_stunned--crop.gif" style="zoom:3"></div>
			<div><img src="@/assets/images/resources/art-assets/zombie1_throat_cut--crop.gif" style="zoom:3"></div>
		</div>

		<br>

		<div class="md-gallery md-gallery--align-bottom md-gallery--spacious">
			<div><img src="@/assets/images/resources/art-assets/pick_up_photo.gif" style="zoom:3"></div>
		</div>
		<div class="md-image-caption">
			This animation comes from the demo, where you can perform this very action.
			<br>What might the picture show -- an old life for Reaper, perhaps?
		</div>

		<br>

		<div class="md-gallery md-gallery--align-bottom md-gallery--spacious">
			<div><img src="@/assets/images/resources/art-assets/reaper_roll.gif" style="zoom:3"></div>
		</div>

		<br>

		<div class="md-gallery md-gallery--align-bottom md-gallery--spacious">
			<div><img src="@/assets/images/resources/art-assets/oven1_open.gif" style="zoom:3"></div>
		</div>
		<div class="md-image-caption">
			Early screen captures of the game show Reaper teleporting to an oven, like this one.
		</div>

		<div class="md-gallery md-gallery--align-bottom md-gallery--spacious">
			<div><img src="@/assets/images/resources/art-assets/sDeadReaper1_1.png" style="zoom:3"></div>
		</div>
		<div class="md-image-caption">
			This dead reaper sprite is still viewable in the vanilla editor.
			<br>It's used in place of the dead candle.
		</div>

		<br>

		<div class="md-gallery md-gallery--align-bottom md-gallery--spacious">
			<div><img src="@/assets/images/resources/art-assets/weapon_wall.png" style="zoom:3"></div>
			<div><img src="@/assets/images/resources/art-assets/reaper_jump_skull.gif" style="zoom:3"></div>
		</div>
		<div class="md-image-caption">
			These sprites come from the original art assets zip.
			<br>They show how the lamp was originaly planned, though its purpose isn't clear.
			<br>When the game was ported to consoles, the lamp was re-introduced.
			<br>Holding it while aiming slows down time, a huge help with console's less-precise controls.
			<br>You can also see the "Tac. Knife + Pistol", another console-exclusive weapon prior to DCP.
		</div>

		<br>

		<div class="md-gallery md-gallery--align-bottom md-gallery--spacious">
			<div><img src="@/assets/images/resources/art-assets/zombieboss1_threaten--joined.gif" width="210"></div>
		</div>

		<div class="md-gallery md-gallery--align-bottom">
			<div><img src="@/assets/images/resources/art-assets/sEfBubbleEmote/sEfBubbleEmote_0.png" style="zoom:2"></div>
			<div><img src="@/assets/images/resources/art-assets/sEfBubbleEmote/sEfBubbleEmote_1.png" style="zoom:2"></div>
			<div><img src="@/assets/images/resources/art-assets/sEfBubbleEmote/sEfBubbleEmote_2.png" style="zoom:2"></div>
			<div><img src="@/assets/images/resources/art-assets/sEfBubbleEmote/sEfBubbleEmote_3.png" style="zoom:2"></div>
			<div><img src="@/assets/images/resources/art-assets/sEfBubbleEmote/sEfBubbleEmote_4.png" style="zoom:2"></div>
			<div><img src="@/assets/images/resources/art-assets/sEfBubbleEmote/sEfBubbleEmote_5.png" style="zoom:2"></div>
		</div>
		<div class="md-image-caption">
			Threaten animation, and emotes.
			<br>Part of a scrapped mechanic, where you'd interogate enemies for information.
		</div>

		<br>

		<div class="md-gallery md-gallery--align-bottom">
			<div><img src="@/assets/images/resources/art-assets/zombie4_drugs_sit--crop.png" style="zoom:3"></div>
		</div>
		<div class="md-image-caption">
			One of the early <a href="#tilesets">tilesets</a> has the name "crack" in it, hinting towards a greater
			<br>darkness associated with the drug Ash, and its destructive potential.
		</div>

		<img src="@/assets/images/resources/art-assets/general-res-nocoat.gif" width="200">

		<br>

		<div class="md-gallery md-gallery--align-bottom">
			<div><img src="@/assets/images/resources/art-assets/Vampire2/vampire2_idle_prep.gif"></div>
			<div><img src="@/assets/images/resources/art-assets/Vampire2/vampire2_jump.gif"></div>
			<div><img src="@/assets/images/resources/art-assets/Vampire2/vampire2_run_prep.gif"></div>
			<div><img src="@/assets/images/resources/art-assets/Vampire2/vampire2_sit.gif"></div>
			<div><img src="@/assets/images/resources/art-assets/Vampire2/vampire2_smash.gif"></div>
		</div>

		<br>

		<div class="md-gallery md-gallery--align-bottom md-gallery--spacious">
			<div><img src="@/assets/images/resources/art-assets/vamp1_dance1_old--crop.gif" width="90"></div>
			<div><img src="@/assets/images/resources/art-assets/Vampire2/vampire2_strip1_keyframes.gif" width="300"></div>
		</div>

		<br>

		<div class="md-gallery md-gallery--align-bottom md-image-hover">
			<div><img src="@/assets/images/resources/art-assets/bike/bike1-empty--crop.png" width="150"></div>
			<div><img src="@/assets/images/resources/art-assets/bike/bike1-skele1--crop.png" width="150"></div>
			<div><img src="@/assets/images/resources/art-assets/bike/bike2-empty--crop.png" width="150"></div>
			<div><img src="@/assets/images/resources/art-assets/bike/bike2-skele2--crop.png" width="150"></div>
		</div>
		<div class="md-image-caption">
			<br>Ever wondered how Generals got around with that huge hammer?
		</div>


	</div>
</template>


<script>
	export default {
		name: 'CutSpritesSingle'
	}
</script>


<style scoped lang="scss">
	.md-gallery img {
		margin-bottom: 10px;
	}
</style>
