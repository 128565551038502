<template>
	<div>
		<div class="text-center">
			<p>Groups of sprites that never made it into the game.</p>
			<p>Source from <a target="_blank" href="https://hopoo.tumblr.com/">Hopoo's blog</a>, and their posts on <a href="https://forums.tigsource.com/index.php?topic=45642.0" target="_blank">Tigsource</a>.</p>
		</div>

		<br>

		<img src="@/assets/images/resources/character-concepts.png">
		<div class="md-image-caption">
			Short vampires and unused wraiths.
		</div>

		<div class="wideout md-image-hover">
			<img src="https://files.codemuffin.com/deadbolt/cdn/assets/images/resources/tigsource/character-concepts-trans.png" alt="">
			<div class="md-image-caption">Unscaled version of the image above, via <a target="_blank" href="https://imgur.com/QMtcLgB">here</a></div>
		</div>

		<div class="wideout">
			<img src="@/assets/images/resources/zombie-alts.gif">
			<div class="md-image-caption">
				Alternate zombie outfits and a chained dog
			</div>
		</div>

		<div class="wideout">
			<img src="https://files.codemuffin.com/deadbolt/cdn/assets/images/resources/tigsource/early-sprites-wi-blue-oni.png" alt="">
			<div class="md-image-caption">Blue demons (Oni), and other characters with minor differences</div>
		</div>

		<div class="wideout">
			<img src="https://files.codemuffin.com/deadbolt/cdn/assets/images/resources/tigsource/level-select-dev.png" alt="">
			<div class="md-image-caption">A very early level select screen</div>
		</div>

		<div class="wideout">
			<img src="https://files.codemuffin.com/deadbolt/cdn/assets/images/resources/tigsource/vamp-women-crop.png" alt="">
			<div class="md-image-caption">
				Hopoo originally planned to have lots of different costumes,
				<br>but scrapped this idea due to the huge amount of work it would have needed.</div>
		</div>

	</div>
</template>


<script>
	export default {
		name: 'CutSpritesGrouped'
	}
</script>


<style scoped lang="scss">
	//
</style>
