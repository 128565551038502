<template>
	<div>
		<div class="text-center">
			<p>Unused background images.</p>
		</div>

		<br>

		<div class="wideout md-image-hover">
			<img src="https://files.codemuffin.com/deadbolt/cdn/assets/images/resources/backgrounds/background9.png" alt="background9">
		</div>

		<div class="wideout md-image-hover">
			<img src="https://files.codemuffin.com/deadbolt/cdn/assets/images/resources/backgrounds/hell_bg.png" alt="hell_bg">
		</div>

	</div>
</template>


<script>
	export default {
		name: 'CutSpritesGrouped'
	}
</script>


<style scoped lang="scss">
	//
</style>
